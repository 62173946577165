.colophon {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 5px;
    font-size: 9px;
    opacity: .75;

    a {
        display: inline-block;
        padding: 0 2px;
        color: var(--dark-color);
        text-decoration: none;
        transition: .3s ease-in;

        &:hover {
            color: var(--primary-color);
        }
    }
}
