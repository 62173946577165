/* Breakpoints */
@custom-media --max-mobile (width <= 480px);
@custom-media --min-tablet (width > 480px);
@custom-media --max-tablet (width <= 768px);
@custom-media --min-desktop (width > 768px);
@custom-media --min-desktop-large (width >= 992px);
@custom-media --min-desktop-xlarge (width >= 1200px);
@custom-media --min-desktop-xxlarge (width >= 1400px);

:root {
    /* Colour palette */
    --primary-color: #346a86;
    --secondary-color: #81708d;
    --tertiary-color: #fbc200;
    --dark-color: #000000;
    --light-color: #FFFFFF;

    /* Shadow */
    --box-shadow: 0 10px 20px color(var(--dark-color) alpha(-75%));

    /* Fonts */
    --primary-font: 'Montserrat', Arial, Verdana, sans-serif;
}
