.icon-github {
  width: 0.32em;
  height: 0.32em;
}

.icon-logo-espais-dinamics {
  width: 0.54em;
  height: 0.66em;
}

.icon-logo-wam {
  width: 1em;
  height: 1em;
}

