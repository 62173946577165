*,
*:before,
*:after {
    box-sizing: border-box;
}

.container {
    @apply --container;
}

.clearfix {
    @apply --clearfix;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

body {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: var(--primary-font);
    background-image: url(../img/bg-mobile.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 80% center;

    @media (--min-tablet) {
        background-image: url(../img/bg.jpg);
        background-position: 70% center;
    }

    @media (--min-desktop) {
        background-position: center center;
    }
}

.main-header,
.main-footer {
    flex: 0 0 auto;
}

.main-container {
    flex: 1 1 auto;

    .container {
        display: flex;
    }
}

.main-content {
    flex: 0 1 75%;
}

.main-sidebar {
    flex: 0 1 25%;
    padding-left: 30px;
}

h1 {
    color: var(--dark-color);
    font-size: 32px;
}
